import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
    connect() {
        this.element.addEventListener("keydown", this.submitOnEnter.bind(this))

    }

    submitOnEnter(event) {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault()
            const form = event.target.closest("form")
            if (form) {
                form.dispatchEvent(new Event("submit", { bubbles: true }))
            }
        }
    }

}
