import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["checkbox"]
    static values = { groupId: Number, projectId: Number, blockId: Number, listId: Number }

    toggle(event) {
        // Immediately update all checkboxes to match the clicked state
        let taskId = this.checkboxTarget.value;
        let done = this.checkboxTarget.checked;
        this.syncCheckboxState(taskId, done);

        let groupId = this.groupIdValue;
        let projectId = this.projectIdValue;
        let blockId = this.blockIdValue;
        let listId = this.listIdValue;
        let data = new FormData();
        data.append("task[done]", done);

        let url = `/g/${groupId}/projects/${projectId}/listblocks/${blockId}/lists/${listId}/tasks/${taskId}/done`;

        fetch(url, {
            body: data,
            method: "PATCH",
            credentials: 'same-origin',
            headers: {
                "X-CSRF-Token": this.getMetaValue("csrf-token"),
                "Accept": "text/vnd.turbo-stream.html, application/html"
            }
        }).then(response => {
            if (!response.ok) {
                // If the server response is an error, revert the checkbox state and notify the user
                this.syncCheckboxState(taskId, !done);
                alert("Something went wrong. Please refresh and try again.");
            }
        })
    }

    syncCheckboxState(taskId, state) {
        const checkboxes = document.querySelectorAll(`input[type='checkbox'][value='${taskId}']`);
        checkboxes.forEach((checkbox) => {
            checkbox.checked = state;
        });
    }

    getMetaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`)
        return element.getAttribute("content");
    }
}
