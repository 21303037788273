import "./controllers"

import "@hotwired/turbo-rails"

// Turbo.session.drive = false
import * as ActiveStorage from "@rails/activestorage"
import LocalTime from "local-time"


LocalTime.start()
ActiveStorage.start()

import "./direct"
import "trix"
import "@rails/actiontext"
