import { Controller } from "@hotwired/stimulus"
import { currentUserId } from '../helpers/auth'

export default class extends Controller {
  static targets = ['ownmsg', 'owndate', 'owndlt']
  static values = {
    authorId: String,
    msgId: String,
  }

  connect() {


    if (document.querySelectorAll(`#${this.element.id}`).length > 1) {
      this.element.remove()
      return
    }

    if (this.hasOwnmsgTarget && this.authorIdValue === currentUserId()) {
      this.ownmsgTarget.classList.add('chatmessage-own')
    }

    if (this.hasOwndateTarget && this.authorIdValue === currentUserId()) {
      this.owndateTarget.classList.add('own-date')
    }


 if (this.hasOwndltTarget && this.authorIdValue !== currentUserId()) {
      this.owndltTarget.remove('dlt_msg')
    }
}

heee(){
     if (this.hasOwndltTarget && this.authorIdValue !== currentUserId()) {

    let urlr = "/pm/" + this.msgIdValue + "/tester"

  fetch(urlr, {
    method: 'POST',
                credentials: 'same-origin',
                headers: {
                "X-CSRF-Token": getMetaValue("csrf-token")
            },
  })
//   .then(function (response) {
//             if (response.status !== 200) {
//                 alert("Something went wrong. Please refresh and try again.");
//             }
//         })
        function getMetaValue(name) {
            const element = document.head.querySelector(`meta[name="${name}"]`)
            return element.getAttribute("content")
        }
}
}

    // this.element.scrollIntoView({ block: 'nearest' })
  }


