import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { message: String,
        modalUrl: String
    };

    togglemenu() {
        const id = this.data.get('id');
        const target = document.querySelector(`[data-id="${id}"]`);
        target.classList.toggle('hidden');

        if (!target.classList.contains('hidden')) {
            const firstInput = target.querySelector('input');
            if (firstInput) {
                firstInput.focus();
            }
        }

        const excludeElements = document.querySelectorAll('.progress-dropdown');

        document.addEventListener('keyup', (event) => {
            if (event.key === 'Escape') {
                target.classList.add('hidden');
            }
        });

        document.addEventListener('click', (event) => {
            let clickedInsideExcludedElement = false;

            excludeElements.forEach((element) => {
                if (element.contains(event.target)) {
                    clickedInsideExcludedElement = true;
                }
            });

            // If the click occurred outside of the excluded elements, hide the target
            if (!clickedInsideExcludedElement && !this.element.contains(event.target)) {
                target.classList.add('hidden');
            }
        });
    }



    toggle_div() {
        const id = this.data.get('id');
        const target = document.querySelector(`[data-id="${id}"]`);
        target.classList.toggle('hidden');

    }

    scroll_to_it() {
        const id = this.data.get('id');
        const target = document.querySelector(`[data-id="${id}"]`);
        target.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
    }
    flashit() {
        const x = document.getElementById("blocks");
        x.classList.add("flashit")
    }

    shakeit() {
        const x = document.getElementById("blocks");
        x.classList.add("shakeit")
    }




    usermodal() {
        window.onclick = function (event) {
            if (event.target.matches('.modalarea')) {
                document.getElementById("usermodal").remove()
                document.getElementById("user_profile").removeAttribute("src")

            }

            document.onkeydown = function (evt) {
                evt = evt || window.event;
                if (evt.keyCode === 27) {
                    document.getElementById("usermodal").remove()
                    document.getElementById("user_profile").removeAttribute("src")
                }
            }
        }
    }

    closeprofile(){
        document.getElementById("usermodal").remove()
        document.getElementById("user_profile").removeAttribute("src")
    }



    taskmodal() {
        window.onclick = function (event) {
            if (event.target.matches('.modalarea')) {
                document.getElementById("taskmodal").remove()
                document.getElementById("show_task").removeAttribute("src")

            }

            document.onkeydown = function (evt) {
                evt = evt || window.Event;
                if (evt.keyCode === 27) {
                    document.getElementById("taskmodal").remove()
                    document.getElementById("show_task").removeAttribute("src")
                }
            }
        }
    }


    hide_task_modal() {
        document.getElementById("taskmodal").style.display = "none";
    }


    hide_move_task_modal() {
        document.getElementById("modal-bottom").classList.remove("open");
    }


    hide_user_modal() {
        document.getElementById("usermodal").style.display = "none";
    }


    eventmodal() {
        window.onclick = function(event) {
            // Check if the click was outside the '.modalarea'
            if (event.target.matches('.modalarea')) {
                document.getElementById("eventmodal").style.display = "none";
                if (document.getElementById("show_event")) {
                    document.getElementById("show_event").removeAttribute("src"); // Remove the 'src' attribute if 'show_event' exists
                }
            }
        }
    
        // Listen for the Escape key to close the modal
        document.onkeydown = function(event) {
            event = event || window.event;
            if (event.keyCode === 27) { // '27' is the keycode for the Escape key
                document.getElementById("eventmodal").style.display = "none";
                if (document.getElementById("show_event")) {
                    document.getElementById("show_event").removeAttribute("src");
                }
            }
        }
    }


    hide_event_modal() {
        document.getElementById("eventmodal").style.display = "none";

    }



    hide_mobile_menu() {
        document.getElementById("remove_mobile_menu").remove()
        document.getElementById("mobile_menu").firstElementChild.removeAttribute("src")

    }

    closemodal() {
        const x = this.data.get('dateid')
        const modal = document.getElementById(x);
        modal.style.display = "none";
    }




    imgmodal() {
        const x = this.data.get('dateid')
        const modal = document.getElementById(x);
        modal.style.display = "block";
        window.onclick = function (event) {
            if (!event.target.matches(modal)) {
                modal.style.display = "none";
            }
        }
        window.onclick = function (event) {
            if (event.target.matches('.modalarea')) {
                modal.style.display = "none";
            }
        }

        document.onkeydown = function (evt) {
            evt = evt || window.Event;
            if (evt.keyCode === 27) {
                modal.style.display = "none";
            }
        }
    }


 confirm(event) {
    if (!(window.confirm(this.message))) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  };
    clode_side() {
        const side1 = document.getElementById("side1");
        const side2 = document.getElementById("layout");

        side1.style.display = 'none'
        side2.className.replace("side2", "")

    }



    show_modal() {
        const bottomSheetModal = document.querySelector('.bottom-sheet-modal');
        const loadingContent = document.querySelector('.loading_modal');
        document.querySelector('.modal-content1').classList.remove('hide');
        bottomSheetModal.classList.add('open');
        document.body.style.overflow = 'hidden';
        history.pushState({ modalOpen: true }, null, this.modalUrlValue);

        const closeModal = () => {
            document.body.style.overflow = '';
            bottomSheetModal.classList.remove('open');
            document.getElementById('show_modal').remove();
            if (history.state && history.state.modalOpen) {
                history.back();
            }
            loadingContent.insertAdjacentHTML('afterbegin', '<turbo-frame id="show_modal" target="_top"><div class="container box boxbrand" style="margin-top:21px"><div class="right"><img class="dropdownsettingsbtn nobtn" src="/icons/settings-inside.svg" width="32" style="margin-top:-4px"></div><div style="margin-bottom:20px" class="showflex"><label for="az" class="checkbox-container"><input type="checkbox" id="ax"><span class="checkmark"></span></label><div class="task_title" style="font-size:20px;margin-top:-5px"><div class="vertical-loading-spinner"><div class="vertical-loading-spinner spinner"></div></div></div></div><br><br><div class="showflex flexcenter"><span class="smalltext" style="margin-right:4px">by</span><div class="circle homeblockimg"><div class="vertical-loading-spinner"><div class="vertical-loading-spinner spinner"></div></div></div></div></div></turbo-frame>');
        }

        // Event listener for the escape key
        const handleKeyDown = (evt) => {
            evt = evt || window.event;
            if (evt.keyCode === 27) {
                closeModal();
                document.removeEventListener('keydown', handleKeyDown);
            }
        }

        // Event listener for clicks outside the modal
        const handleClickOutside = (event) => {
            if (event.target.matches('.open')) {
                closeModal();
                window.removeEventListener('click', handleClickOutside);
            }
        }

        // Add event listeners
        document.addEventListener('keydown', handleKeyDown);
        window.addEventListener('click', handleClickOutside);

        // Handle popstate event to close modal when history back is triggered
        window.onpopstate = () => {
            if (!history.state || !history.state.modalOpen) {
                closeModal();
            }
        }
    }
    close_modal() {
        const bottomSheetModal = document.querySelector('.bottom-sheet-modal');
        const loadingContent = document.querySelector('.loading_modal');

            document.body.style.overflow = '';
            bottomSheetModal.classList.remove('open');
            document.getElementById('show_modal').remove()
            // history.back();
            loadingContent.insertAdjacentHTML('afterbegin', '<turbo-frame id="show_modal" target="_top"><div class="container box boxbrand" style="margin-top:21px"><div class="right"><img class="dropdownsettingsbtn nobtn" src="/icons/settings-inside.svg" width="32" style="margin-top:-4px"></div><div style="margin-bottom:20px" class="showflex"><label for="az" class="checkbox-container"><input type="checkbox" id="ax"><span class="checkmark"></span></label><div class="task_title" style="font-size:20px;margin-top:-5px"><div class="vertical-loading-spinner"><div class="vertical-loading-spinner spinner"></div></div></div></div><br><br><div class="showflex flexcenter"><span class="smalltext" style="margin-right:4px">by</span><div class="circle homeblockimg"><div class="vertical-loading-spinner"><div class="vertical-loading-spinner spinner"></div></div></div></div></div></turbo-frame>');

    }




    updateZoomLevel() {
        const zoomLevel = this.element.value;
        const timeline_index = document.getElementById("timeline_index");
        timeline_index.style.zoom = `${zoomLevel}%`;
    }

    mark_notification_read(){
        this.element.classList.add("opc");
    }

}
