import { Controller } from "@hotwired/stimulus"
import { currentUserId } from '../helpers/auth'

export default class extends Controller {
  static targets = ['ownmsg', 'owndate', 'owndlt']
  static values = {
    authorId: String,
    msgId: String,
  }

  connect() {

    if (document.querySelectorAll(`#${this.element.id}`).length > 1) {
      this.element.remove()

      return
    }

    if (this.hasOwnmsgTarget && this.authorIdValue === currentUserId()) {
      this.ownmsgTarget.classList.add('chatmessage-own')
this.seen()
      return
    }

    if (this.hasOwndateTarget && this.authorIdValue === currentUserId()) {
      this.owndateTarget.classList.add('own-date')
    }


    if (this.hasOwndltTarget && this.authorIdValue !== currentUserId()) {
      this.owndltTarget.remove('dlt_msg')
      // this.seen()
    }
  }

  seen(){
    let urlr = "/pm/" + this.msgIdValue + "/seen"

    fetch(urlr, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
    })

    function getMetaValue(name) {
      const element = document.head.querySelector(`meta[name="${name}"]`)
      return element.getAttribute("content")
    }
  }
}



