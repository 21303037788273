import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.transformUserMentions();
  }

  transformUserMentions() {
    const trixEditor = this.element.querySelector("#trix-body");

    if (trixEditor) {
      let htmlContent = trixEditor.innerHTML;

      // Regex to handle potential extra whitespace and HTML entities more robustly
      const userMentionRegex = /@([\w\s]+)\[userID:(\d+)\]/;

      const match = htmlContent.match(userMentionRegex);

      if (match) {
        const userName = match[1].trim(); // Trim to remove potential leading/trailing whitespace
        const userId = match[2];

        console.log(`Username: ${userName}, User ID: ${userId}`);

        // Replace the user mention with a link to the user's profile
        htmlContent = htmlContent.replace(match[0], `<a href="/profile/${userId}">@${userName}</a>`);

        // Directly manipulating trixEditor.innerHTML may not reflect changes due to Trix's internal content handling.
        // Consider transforming the mentions outside of the Trix editor or using Trix's API for content manipulation.
      } else {
        console.log("No user mention found in the content.");
      }
    }
  }
}
