// // save_controller.js
// import { Controller } from "@hotwired/stimulus"
//
// export default class extends Controller {
//     static targets = ["inp_text", "status"];
//
//     connect() {
//         this.setupAutosave();
//     }
//
//     setupAutosave() {
//         this.inp_textTarget.addEventListener("input", () => {
//             clearTimeout(this.timeoutId);
//             this.timeoutId = setTimeout(() => {
//                 this.saveData();
//             }, 2000);
//         });
//     }
//
//     saveData() {
//         // const formData = new FormData(this.element);
//         const inputfield = document.querySelector('#note_body');
//         const form = document.querySelector('form');
//         const savedMessage = document.getElementById('hii');
//
//         inputfield.addEventListener('input', () => {
//             const formData = new FormData(form);
//
//         fetch(this.data.get("url"), {
//             method: "PATCH",
//             body: formData,
//             credentials: 'same-origin',
//             headers: {
//                 "X-CSRF-Token": getMetaValue("csrf-token")
//             }
//         }).then(() => {
//             savedMessage.style.display = 'block';
//             setTimeout(() => {
//                 savedMessage.style.display = 'none';
//             }, 2000);
//         });
//         });
//         function getMetaValue(name) {
//             const element = document.head.querySelector(`meta[name="${name}"]`)
//             return element.getAttribute("content")
//         }
//
//     }
//
// }
