import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
    static targets = ["field"]
    static values = { groupId: Number, projectId: Number }

    connect() {

        // Ensure the Trix editor is properly targeted and initialized
        if (this.hasFieldTarget) {
            this.initializeTribute();
        } else {
            console.error("Trix editor target not found.");
        }
    }

    disconnect() {
        if (this.tribute) {
            this.tribute.detach(this.fieldTarget);
        }
    }

    initializeTribute() {

        this.tribute = new Tribute({
            allowSpaces: true,
            lookup: 'name',
            values: this.fetchUsers.bind(this),
        });

        this.tribute.attach(this.fieldTarget);

        // Note: Replacing _pasteHtml with a correct approach since getPosition is not a Trix method
        this.fieldTarget.addEventListener("tribute-replaced", this.replaced.bind(this));
    }

    fetchUsers(text, callback) {
        let groupId = this.groupIdValue;
        let projectId = this.projectIdValue;

        fetch(`/g/${groupId}/projects/${projectId}/mentions.json?query=${text}`)
            .then(response => response.json())
            .then(users => callback(users))
            .catch(error => {
                console.error("Error fetching users:", error);
                callback([]);
            });
    }

    replaced(e) {
        const mention = e.detail.item.original;
        const attachment = new Trix.Attachment({
            sgid: mention.sgid,
            content: mention.content
        });

        const editorElement = this.fieldTarget;
        const editor = editorElement.editor;

        if (editor) {
            // Get the current selection range
            const currentRange = editor.getSelectedRange();

            // Get the full editor content text
            const text = editor.getDocument().toString();

            // Search for the trigger text starting from the current selection range start
            let startIndex = currentRange[0] - 1;
            while (startIndex > 0 && text[startIndex] !== '@') {
                startIndex--;
            }

            // Check if we found a potential trigger start
            if (text[startIndex] === '@') {
                // Delete the trigger text by setting the selected range and deleting it
                editor.setSelectedRange([startIndex, currentRange[0]]);
                editor.deleteInDirection("backward");

                // Insert the attachment and a space after it
                editor.insertAttachment(attachment);
                editor.insertString(" ");

                // Move the cursor after the inserted mention
                const newRange = editor.getSelectedRange();
                editor.setSelectedRange([newRange[1], newRange[1]]);
            } else {
                console.error("Could not find the trigger '@' in the text.");
            }
        }
    }



}
