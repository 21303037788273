import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submit"]
  static values = { message: String };
  values
  disconnect() {
    this.enableSubmits()
    this.element.toggleAttribute("data-submitting", false)
}


  submit(event) {
    const form = event.target.form || event.target.closest("form")
    if (form) form.requestSubmit()
  }

  disableSubmits() {
    this.submitTargets.forEach(submitTarget => submitTarget.disabled = true)
  }

  enableSubmits() {
    this.submitTargets.forEach(submitTarget => submitTarget.disabled = false)
    this.element.reset()
  }

  scrolltoview(){
    //   document.getElementById("scrolltohere").scrollIntoView()
    //    this.element.scrollIntoView()

      
       this.element.scrollIntoView({ block: 'nearest' })
  }

  submitStart(event) {
    const form = this.element
    if (form) {
      form.toggleAttribute("data-submitting", true)
      this.disableSubmits()

    }
  }

  confirm(event) {
    if (!(window.confirm(this.confirmMessageValue))) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  };

hideframe() {
    document.getElementById("remove_frame").remove()
    document.getElementById("hidesrc").firstElementChild.removeAttribute("src")
}

clearUpload(){
        const output = document.getElementById('fileList');
         output.innerHTML = '+ Select file';
}

  hidemodel() {
    document.getElementById("modeldiv").remove()
  }




}
