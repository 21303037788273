import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            group: 'nested',
            easing: "cubic-bezier(.97,.53,.48,.86)",
            animation: 150,
            handle: ".grab",
            onEnd: this.end.bind(this)
        //     fallbackOnBody: true,
		// swapThreshold: 0.65

        })
    }

    end(event) {
        let id = event.item.dataset.id
        let to_id = event.to.dataset.id
        let data = new FormData()
        let url = this.data.get("url").replace(":id", id)
          data.append("position", event.newIndex + 1)
          data.append("to_list_id", to_id)

        fetch(url, {
                body: data,
                method: "PATCH",
                credentials: 'same-origin',
                headers: {
                "X-CSRF-Token": getMetaValue("csrf-token")
            }}
        ).then(function (response) {
            if (response.status !== 200) {
                alert("Something went wrong. Please refresh and try again.");
            }
        })
        function getMetaValue(name) {
            const element = document.head.querySelector(`meta[name="${name}"]`)
            return element.getAttribute("content")
        }
    }
}