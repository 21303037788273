import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    static targets = ["field", "bottomSheetModal", "loadingContent", "modalContent"];

    connect() {
        // Add popstate event listener when the component connects
        window.addEventListener('popstate', this.handlePopState.bind(this));
        this.show_modal();
    }

    disconnect() {
        // Clean up: Remove event listeners when the component disconnects
        window.removeEventListener('popstate', this.handlePopState.bind(this));
        document.removeEventListener('keydown', this.handleKeyDown.bind(this));
        window.removeEventListener('click', this.handleClickOutside.bind(this));
    }

    show_modal() {
        this.modalContentTarget.classList.remove('hide');
        this.bottomSheetModalTarget.classList.add('open');
        document.body.style.overflow = 'hidden';
        history.pushState({ modalOpen: true }, null, this.modalUrlValue);

        // Bind event listeners
        document.addEventListener('keydown', this.handleKeyDown.bind(this));
        window.addEventListener('click', this.handleClickOutside.bind(this));
    }

    closeModal() {
        document.body.style.overflow = '';
        this.bottomSheetModalTarget.classList.remove('open');
        this.loadingContentTarget.innerHTML = '...'; // Reset the loading content as needed
        if (history.state && history.state.modalOpen) {
            history.back();
        }
    }

    handleKeyDown(evt) {
        if (evt.keyCode === 27) { // ESC key
            this.closeModal();
        }
    }

    handleClickOutside(event) {
        if (event.target.matches('.open')) {
            this.closeModal();
        }
    }

    handlePopState(event) {
        // Check the state to decide whether to close the modal
        if (!history.state || !history.state.modalOpen) {
            this.closeModal();
        }
    }
}
