import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "start_date","end_date", "tool" ]
    static values = {
        startId: String, endId: String,

    }
    connect() {
        const bodyRect = document.getElementById("all_dates").getBoundingClientRect();

        if (this.hasStart_dateTarget) {

            const div_start = document.getElementById(this.startIdValue).getBoundingClientRect();
            const end_start = document.getElementById(this.endIdValue).getBoundingClientRect();

            const offset = div_start.left - bodyRect.left;
            this.start_dateTarget.style.left = offset + 'px';

            const offset1 = end_start.left - div_start.left;
            this.start_dateTarget.style.width = (offset1 + 10) + 'px';
        }


    }

}