// edit_note_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.element.querySelectorAll('a').forEach(function(link) {
            link.target = "_blank"
            link.rel = "noreferrer"

        })
    }

}
